<template>
  <main class="error">
    <h2>Opps! Page Not Found</h2>
    <p>Go back to the <a href="/">home page.</a></p>
  </main>
</template>

<script>
export default {
  name: "error",
};
</script>
